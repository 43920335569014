import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_PROFILE } from "../graphql/queries";
import UserContext from "../context/UserContext";
import AppContext from "../context/AppContext";

export default function MainLayout() {
   const [getUserProfile] = useLazyQuery(GET_USER_PROFILE, {
      fetchPolicy: "network-only",
   });
   const [user, setUser] = useState<any>();
   const navigate = useNavigate();
   const appCtx = useContext(AppContext);

   const handleUserProfile = (res: any) => {
      if (!res.data) {
         navigate("/logout", { replace: true });
         return;
      }
      let resp = res.data.getUserProfile;
      if (resp.result !== 0) {
         navigate("/login", { replace: true });
         return;
      }
      setUser((prev: any) => {
         return { email: resp.user.email, role: resp.user.role, transporter: resp.user.transporter };
      });
      appCtx?.changeAuth(1);
   };

   useEffect(() => {
      setTimeout(() => {
         getUserProfile()
            .then((res) => {
               handleUserProfile(res);
            })
            .catch((err) => {
               console.error({ err });
               appCtx?.changeAuth(-2);
            });
      }, 100);
   }, []);

   if (!user) {
      return null;
   }
   return (
      <div className='main-container bg-slate-50 flex'>
         <UserContext.Provider value={{ user, setUser: (user: any) => setUser(user) }}>
            <Sidebar />
            <div className='flex-1 content flex flex-col gap-2'>
               <div className='flex justify-end px-2 py-2 items-center gap-2 bg-slate-100'>
                  <aside className='flex justify-center flex-col items-end'>
                     <h3 className='text-sm'>{user.email}</h3>
                     <p className='font-bold text-xs'>{user.role.name}</p>
                  </aside>
                  <img src='/images/avatar-icon.svg' alt='Avatar' width={32} />
               </div>
               <Outlet />
            </div>
         </UserContext.Provider>
      </div>
   );
}
