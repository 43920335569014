import React, { useEffect, useRef, useState } from "react";
import { SelectChoice } from "../../../helpers/types";
import OutsideAlerter from "../../../hooks/OutClickNotifier";
const Icon = () => {
   return (
      <svg height='20' width='32' viewBox='0 0 20 20'>
         <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
      </svg>
   );
};
const CloseIcon = () => {
   return (
      <svg height='16' width='16' viewBox='0 0 20 20'>
         <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
      </svg>
   );
};

const MultiSelect = ({
   placeHolder,
   choices,
   isMulti,
   formData,
   options,
   ...rest
}: {
   name: string;
   placeHolder?: string;
   choices: SelectChoice[];
   options?: {
      required?: boolean;
      isRequired?: Function;
   };
   isMulti?: boolean;
   readOnly?: boolean;
   onChange?: Function;
   formData?: Map<string, any>;
}) => {
   const initVal = (): SelectChoice | SelectChoice[] | undefined => {
      const value = formData?.get(rest.name);
      console.log({formData})
      if (!value) return isMulti ? [] : undefined;

      if (Array.isArray(value)) return choices.filter((o) => value.includes(o.value));

      let res = choices.find((o) => o.value === value);
      return res;
   };

   const initValue = initVal();
   const [show, setShow] = useState(false);
   const [isChanged, setIsChanged] = useState(false);
   const [selected, setSelected] = useState<SelectChoice | SelectChoice[] | undefined>();
   const [searchValue, setSearchValue] = useState("");
   const searchRef = useRef<HTMLInputElement | any>();
   const selectElRef = useRef<HTMLDivElement>(null);

   const getDisplay = () => {
      if (!selected || (Array.isArray(selected) && selected.length === 0)) {
         return <span className='text-gray-400 text-xs'>{placeHolder}</span>;
      }
      if (Array.isArray(selected)) {
         return (
            <div className='dropdown-tags flex flex-wrap gap-2 pt-1'>
               {selected.map((option) => (
                  <div key={option.value} className='dropdown-tag-item border rounded flex items-center pl-1 text-xs'>
                     {option.label}
                     <span onClick={(e) => onTagRemove(e, option)} className='dropdown-tag-close flex items-center'>
                        <CloseIcon />
                     </span>
                  </div>
               ))}
            </div>
         );
      }
      return selected.label;
   };
   const removeOption = (option: SelectChoice) => {
      if (!Array.isArray(selected)) return [];
      return selected.filter((o) => o.value !== option.value);
   };
   const onTagRemove = (e: any, option: SelectChoice) => {
      e.stopPropagation();
      if (!Array.isArray(selected)) return;
      setSelected(removeOption(option));
   };
   const handleSelectClick = (ev: any) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (ev.type === "click") {
         setShow(!show);
      }
   };

   useEffect(() => {
      const handler = () => setShow(false);
      window.addEventListener("click", handler);
      if (selectElRef.current) {
         let el = selectElRef.current;
         let rect = el.getBoundingClientRect();
         el.style.setProperty("--pos-top", `${rect.top}`);
         el.style.setProperty("--pos-left", `${rect.left}`);
         el.style.setProperty("--pos-width", `${rect.width}`);
      }
      return () => window.removeEventListener("click", handler);
   }, []);
   useEffect(() => {
      setSearchValue("");
      if (show && searchRef.current) {
         searchRef.current?.focus();
      }
   }, [show]);

   const getValue = () => {
      if (selected === null) return null;
      if (!Array.isArray(selected)) return selected?.value;
      return selected.map((i) => i.value);
   };
   useEffect(() => {
      if (!isChanged) {
         setIsChanged(true);
         return;
      }
      if (!rest.onChange) return;

      console.log({initVal: initVal()})
      rest.onChange({
         name: rest.name,
         value: getValue(),
      });
   }, []);

   useEffect(() => {
      setSelected(initValue);
   }, [initValue]);

   const onItemClick = (choice: SelectChoice) => {
      let newValue:any;
      if (Array.isArray(selected)) {
         if (selected.findIndex((o) => o.value === choice.value) >= 0) {
            newValue = removeOption(choice);
         } else {
            newValue = [...selected, choice];
         }
      } else {
         newValue = choice;
      }
      setSelected(newValue);
      console.log({newValue});
      rest.onChange && rest.onChange({
         name: rest.name,
         value: newValue.value,
      });
   };

   const isSelected = (choice: SelectChoice) => {
      if (Array.isArray(selected)) {
         return selected.filter((o) => o.value === choice.value).length > 0;
      }
      if (!selected) return false;
      return selected.value === choice.value;
   };
   const onSearch = (e: any) => {
      setSearchValue(e.target.value);
   };
   const getOptions = () => {
      if (!searchValue) {
         return choices;
      }
      return choices.filter((option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
   };

   return (
      <div
         className={`dropdown-container bottom-1 rounded h-full top-0 ${rest.readOnly ? "readonly" : ""}`}
         ref={selectElRef}
      >
         <input
            type='text'
            name={rest.name}
            // defaultValue={formData?.get(rest.name)}
            required={options?.required || undefined}
            className='multiselect-value-holder-input'
         />
         <div
            className='dropdown-input flex items-center justify-between select-none h-full gap-2'
            style={{ minWidth: 0 }}
            onClick={handleSelectClick}
         >
            <div className='dropdown-selected-value flex-1 pl-3 text-xs'>{getDisplay()}</div>
            <div className='dropdown-tools'>
               <div className='dropdown-tool'>
                  <Icon />
               </div>
            </div>
         </div>
         {show && !rest.readOnly && (
            <OutsideAlerter onOutClick={() => setShow(false)}>
               <div className='dropdown-menu absolute overflow-auto bg-white w-full border top-10 shadow-lg rounded'>
                  <div className='search-box' onClick={(ev: any) => ev.stopPropagation()}>
                     <input
                        onChange={onSearch}
                        value={searchValue}
                        ref={searchRef}
                        className='w-full border-b outline-none px-3 py-1 text-sm'
                        placeholder='Filter ...'
                     />
                  </div>
                  <div className='dropdown-options-wrap flex flex-col'>
                     {getOptions().map((o, idx) => (
                        <div
                           key={o.value}
                           className={`dropdown-item cursor-pointer text-xs px-3 py-1 ${isSelected(o) && "selected"}`}
                           onClick={() => onItemClick(o)}
                        >
                           {o.label}
                        </div>
                     ))}
                  </div>
               </div>
            </OutsideAlerter>
         )}
      </div>
   );
};

export default MultiSelect;
