import { useContext, useEffect, useState } from "react";
import CreateNewRecordForm from "./CreateNewRecordForm";
import { useLazyQuery } from "@apollo/client";
import { GET_INSPECTIONS } from "../../graphql/queries";
import ViewRecordForm from "./ViewRecordForm";
import usePagination from "../../hooks/usePagination";
import UserContext from "../../context/UserContext";

function RecordsList() {
   const userCtx = useContext(UserContext)
   const [newRecord, setNewRecord] = useState<boolean>();
   const [viewRecords, setViewRecords] = useState<boolean>();
   const [selected, setSelected] = useState();
   const [totalRecords, setTotalRecords] = useState(1);
   const { Pagination, pageInfo, setPageInfo } = usePagination(8);
   const [getInspections, { loading }] = useLazyQuery(GET_INSPECTIONS, {
      fetchPolicy: "network-only",
   });

   const [data, setData] = useState<any[]>([]);
   const [filter, setFilter] = useState("");
   const handleFilter = (value: string) => {
      console.log({ value });
      setFilter(value);
   };


   function refresh(page: number = 1) {
      const transporter = userCtx.user.transporter
      let vars: any = {
         filter,
         page: page,
         limit: pageInfo.limit,
      }
      if (transporter) {
         vars["transporterId"] = transporter.id
      }
      console.log({vars, transporter})
      getInspections({
         variables: vars
      }).then((res) => {
         console.log({ res });
         if (res.data) {
            let resData = res.data.getAllInspections;
            setTotalRecords((prev) => resData.totalRecords);
            setData(resData.records);
         }
      });
   }

   const updated = () => {
      setNewRecord(false);
      refresh();
   };

   useEffect(() => {
      setPageInfo((prev) => ({ ...prev, page: 1 }));
   }, [pageInfo.limit]);

   useEffect(() => {
      refresh(pageInfo.page);
   }, [pageInfo, filter]);

   function displaySetup(s: any) {
      if (!s) return null;
      let name = s.fields.find((f: any) => f.name === "name");
      if (name) return name.value;
   }

   return (
      <>
         <div className='toolbar flex justify-between px-2 py-2 items-center gap-10'>
            <h3>List of records</h3>
            <div className='flex gap-1 flex-1'>
               <input
                  type='search'
                  name='search'
                  placeholder='Filter ...'
                  className='flex-1 px-3 py-1 rounded-lg w-full outline-1 outline-slate-400'
                  onChange={(ev) => handleFilter(ev.target.value)}
                  defaultValue={filter}
               />
               <button className='bg-primary px-2 py-1 rounded text-white' onClick={() => setNewRecord(true)}>
                  Add New
               </button>
               {newRecord && <CreateNewRecordForm onComplete={() => updated()} />}
               {viewRecords && <ViewRecordForm record={selected} onComplete={() => setViewRecords(false)} />}
            </div>
         </div>
         <div className='w-full p-2 overflow-y-auto table-wrap'>
            <table className='table border-collapse border border-slate-400 table-auto w-full text-left'>
               <thead className='bg-slate-100'>
                  <tr>
                     <th className='border border-slate-300 px-2'>#</th>
                     <th className='border border-slate-300 px-2'>Transporter</th>
                     <th className='border border-slate-300 px-2'>Customer</th>
                     <th className='border border-slate-300 px-2'>Site</th>
                     <th className='border border-slate-300 px-2'>Vehicle Type</th>
                     <th className='border border-slate-300 px-2'>Reg. Number</th>
                     <th className='border border-slate-300 px-2'>Driver</th>
                     <th className='border border-slate-300 px-2'></th>
                  </tr>
               </thead>
               <tbody className="text-xs">
                  {loading ? (
                     <p className='px-2 text-orange-400'>Loading ...</p>
                  ) : !data ? (
                     <p className='px-2'>No data found</p>
                  ) : (
                     data.map((i: any, idx: number) => (
                        <tr key={idx}>
                           <td className='border border-slate-300 px-2'>{i.id}</td>
                           <td className='border border-slate-300 px-2'>{displaySetup(i.transporter)}</td>
                           <td className='border border-slate-300 px-2'>{displaySetup(i.customer)}</td>
                           <td className='border border-slate-300 px-2'>{displaySetup(i.site)}</td>
                           <td className='border border-slate-300 px-2'>{i.vehicleType}</td>
                           <td className='border border-slate-300 px-2'>{i.vehicleReg}</td>
                           <td className='border border-slate-300 px-2'>{displaySetup(i.driver)}</td>
                           <td className='border border-slate-300 px-2 buttons flex gap-2'>
                              <button
                                 onClick={() => {
                                    setSelected(i);
                                    setViewRecords(true);
                                 }}
                              >
                                 <img src='/images/checklist-24.png' alt='Checklist' />
                              </button>
                           </td>
                        </tr>
                     ))
                  )}
               </tbody>
            </table>
         </div>
         <Pagination totalRecords={totalRecords} />
      </>
   );
}

export default RecordsList;
