import { useContext } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../context/UserContext";
type SidebarMenu = {
   path: string,
   label: string,
   privilege?: string,
   allowSA?: boolean
}
export default function Sidebar() {
   const userCtx = useContext(UserContext)
   console.log({ user: userCtx.user })
   const userPrivileges = userCtx.user.role.permissions.map((p: any) => p.privilege)
   console.log({ userPrivileges })
   const menus: SidebarMenu[] = [
      { path: "/", label: "Dashboard", privilege: "ViewDashboard" },
      { path: "/records", label: "Records", privilege: "ViewRecords" },
      { path: "/setups", label: "Setups", privilege: "ManageSetups" },
      { path: "/reports", label: "Reports", privilege: "ViewReports" },
      { path: "/users", label: "Users", privilege: "ManageUsers", allowSA: true },
      { path: "/roles", label: "Roles", privilege: "ManageRoles", allowSA: true },
   ];
   const allowMenu = (m: SidebarMenu) => {
      if (!m.privilege) return true
      if (userPrivileges.includes(m.privilege)) return true
      if (m.allowSA && userCtx.user.role.superadmin) return true
      return false
   }
   return (
      <div className='sidebar flex flex-col h-full'>
         <h3 className='px-2 py-3 bg-amber-300 font-bold text-lg'>Twiga Safety</h3>
         <ul className='flex-1'>
            {menus.filter(m => allowMenu(m)).map((m, idx) => (
               <li key={idx}>
                  <NavLink to={m.path} className={({ isActive, isPending }) => (isActive ? "menu active" : "menu")}>
                     {m.label}
                  </NavLink>
               </li>
            ))}
         </ul>
         <ul>
            <li>
               <NavLink to='/logout' className={({ isActive, isPending }) => (isActive ? "menu active" : "menu")}>
                  Logout
               </NavLink>
            </li>
         </ul>
      </div>
   );
}
