import { MouseEventHandler, useState } from "react";
import { FormField } from "../helpers/types";
import FormInputField from "../components/forms/inputs/FormInputField";
import SelectField from "../components/forms/inputs/SelectField";

export type FieldData = {
   name: string;
   value: any;
};
export type UseFormParams = {
   onSubmit: Function;
   form: {
      stages: {
         fields: FormField[];
      }[];
   };
   stage: number;
   handleBack?: MouseEventHandler;
   submitText: "Next" | "Submit";
};
function usePagination(limit: number) {
   const pageSizes = [20, 100, 1000]
   const [pageInfo, setPageInfo] = useState({
      page: 1,
      limit: pageSizes.find(p => p === limit) || pageSizes[0],
   });

   const Pagination = ({ totalRecords }: { totalRecords: number }) => {
      const noPages = Math.ceil(totalRecords / pageInfo.limit);
      return (
         <div className='flex justify-between p-2'>
            <div>
               <SelectField
                  name='limit'
                  label='Page: '
                  choices={[...Array(noPages).keys()].map((n) => ({
                     label: `${(n + 1).toLocaleString()}`,
                     value: `${n + 1}`,
                  }))}
                  onBlur={() => console.log()}
                  onChange={(ev: any) => setPageInfo((prev) => ({ ...prev, page: parseInt(ev.target.value) }))}
                  selected={`${pageInfo.page}`}
               />{" "}
               / {noPages.toLocaleString()} of {totalRecords.toLocaleString()} record(s)
            </div>
            <div>
               <SelectField
                  name='limit'
                  label='Page size: '
                  choices={[20, 100, 1000].map((n) => ({ label: `${n.toLocaleString()}`, value: `${n}` }))}
                  onBlur={() => console.log()}
                  onChange={(ev: any) => setPageInfo((prev) => ({ ...prev, limit: parseInt(ev.target.value) }))}
                  selected={`${pageInfo.limit}`}
               />
            </div>
         </div>
      );
   };

   return { Pagination, pageInfo, setPageInfo };
}

export default usePagination;
