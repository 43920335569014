import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ALL_PRIVILEGES, GET_ALL_ROLES, GET_ALL_USERS, SUBMIT_CREATE_ROLE_REQUEST } from "../../../graphql/queries";
import Table from "../../../components/tabular/Table";
import Modal from "../../../components/modal/Modal";
import FormWrapper from "../../../components/forms/FormWrapper";
import { FormField } from "../../../helpers/types";




export default function RolesPage() {

   const [getRoles, { loading }] = useLazyQuery(GET_ALL_ROLES, {
      fetchPolicy: "no-cache",
   });
   const [getPrivileges] = useLazyQuery(GET_ALL_PRIVILEGES, {
      fetchPolicy: "no-cache",
   });
   const [submitCreateRoleReq, { loading: submitLoading }] = useMutation(SUBMIT_CREATE_ROLE_REQUEST);
   const [data, setData] = useState([])
   const [privileges, setPrivileges] = useState([])
   const [state, setState] = useState<{ edit: boolean, selected: any, new: boolean }>({ new: false, edit: false, selected: undefined })
   const [formData, setFormData] = useState(new Map<string, any>())
   const handleOnChange = ({ name, value }: any) => {
      setFormData((prev) => {
         let updated = new Map(prev);
         updated.set(name, value);
         return updated
      })
   }

   const formFields: FormField[] = [
      {
         name: "name",
         label: "Name",
         cols: 2
      }, {
         name: "superadmin",
         label: "Superadmin",
         cols: 2,
         type: "radio",
         choices: [{
            label: "Yes",
            value: "true"
         }, {
            label: "No",
            value: "false"
         }]
      }, {
         label: "Privileges", name: "privileges", type: "checkbox", cols: 2, choices: privileges.map((p: any) => ({ label: p.name, value: p.name }))
      }
   ]

   const TABLE_COLUMNS = [{ "label": "ID", name: "id" }, {
      label: "Name",
      name: "name",
   }, {
      label: "Permissions",
      name: "permissions",
      render: ({ row }: any) => <div className="flex flex-wrap gap-1 text-sm">
         {row.permissions.map((p: any) => {
            return <span className="px-1 border rounded">{p.privilege}</span>
         })}
      </div>
   }, {
      label: "Superadmin",
      name: "superadmin",
      render: ({ row }: any) => (<input disabled type="checkbox" checked={row.superadmin} />)
   }, {

      name: "action",
      render: ({ row }: any) => (<div className="flex gap-1 items-center"><button onClick={() => {
         setFormData(prev => {
            prev.set("name", row.name);
            prev.set("privileges", row.permissions.map((p: any) => p.privilege))
            prev.set('superadmin', `${row.superadmin || false}`)
            return new Map(prev)
         })
         setState((prev) => ({ ...prev, edit: true, selected: row }));
      }}>Edit</button></div>)
   }]

   const submitCreateRole = (ev: any) => {
      ev.preventDefault()
      let submitData = { "superadmin": formData.get('superadmin') === "true", "id": state.selected ? state.selected.id : null, "name": formData.get("name"), "privileges": formData.get("privileges") || [] }
      submitCreateRoleReq({
         variables: {
            data: submitData
         },
      }).then(res => {
         refreshList();
         setState(prev => ({ ...prev, edit: false, selected: null }))
      })
   }

   const refreshList = () => {
      getRoles({
         variables: {}
      }).then(res => {
         setData(res.data.getRoles)
      })
   }
   useEffect(() => {
      refreshList()

      getPrivileges({ variables: {} }).then(res => setPrivileges(res.data.getPrivileges))

   }, []);
   return (
      <div>
         <div>
            <Table
               columns={[...TABLE_COLUMNS]}
               data={data}
               loading={loading}
               title={
                  <b>Roles</b>
               }
               components={{
                  toolbar: () => (
                     <div className='flex gap-1'>
                        <button className="bg-blue-500 px-2 py-1 text-white border rounded" onClick={() => setState(prev => ({ ...prev, new: true }))}>New Role</button>
                     </div>
                  ),
               }}
            />
         </div>
         {state.edit &&
            <Modal className="w-32" modalId="editRole" header={
               <div>Edit role: {state.selected.name}</div>} handleClose={() => setState((prev) => ({ ...prev, edit: false }))}>
               <form onSubmit={submitCreateRole}>
                  <FormWrapper fields={formFields} formData={formData} updateFormData={handleOnChange} />
                  <div className="flex justify-end items-center py-1">
                     <button disabled={submitLoading} type="submit" className="bg-blue-500 px-2 py-1 text-white border rounded">{submitLoading ? "Please wait ..." : "Submit"}</button>
                  </div>
               </form>
            </Modal>
         }
         {state.new &&
            <Modal className="w-32" modalId="editRole" header={
               <div>New role</div>} handleClose={() => setState((prev) => ({ ...prev, new: false }))}>
               <form onSubmit={submitCreateRole}>
                  <FormWrapper fields={formFields} formData={formData} updateFormData={handleOnChange} />
                  <div className="flex justify-end items-center py-1">
                     <button disabled={submitLoading} type="submit" className="bg-blue-500 px-2 py-1 text-white border rounded">{submitLoading ? "Please wait ..." : "Submit"}</button>
                  </div>
               </form>
            </Modal>
         }
      </div>
   );
}
