import ReactDom from "react-dom";
import OutsideAlerter from "../../hooks/OutClickNotifier";

type ModalParams = {
   RootEl?: HTMLElement
   modalId?: string;
   handleClose?: Function;
   title?: any;
   children?: any;
   large?: boolean;
   header?: any;
   footer?: any;
   className?: string;
};

function Modal({ modalId, handleClose, children, header, footer, large, className, ...rest }: ModalParams) {
   const otherClick = (e: any) => {
      if (e.target.id === modalId && handleClose) {
         handleClose();
      }
   };

   const showHideClassName = "modal display-block";
   const modalPortal = document.getElementById("modal-root");
   return modalPortal
      ? ReactDom.createPortal(
           <div className={showHideClassName} onClick={otherClick} id={modalId}>
              <OutsideAlerter
                 onOutClick={() => handleClose && handleClose()}
                 className={(large ? "modal-main large" : "modal-main") + (" " + className || "")}
              >
                 {header && <div className='modal-header'>{header}</div>}
                 <div className='modal-content'>{children}</div>
                 {footer && <div className='modal-footer'>{footer}</div>}
              </OutsideAlerter>
           </div>,
           modalPortal
        )
      : null;
}

export default Modal;
